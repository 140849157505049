import React from "react";
import theme from "theme";
import { Theme, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaExternalLinkAlt, FaAngleDoubleDown, FaRegQuestionCircle, FaCcStripe, FaShieldAlt } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"payment"} />
		<Helmet>
			<title>
				Payment
			</title>
			<meta name={"description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:title"} content={"Brain Rank IQ Test"} />
			<meta property={"og:description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/proveit.webp?v=2024-03-04T16:16:07.691Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
		</Helmet>
		<Components.NavBar />
		<Components.PaymentWrapper
			clientId="pk_live_51OsTE2DH1lec11cOErlTYRrB02fusMAyszaYZHf8KAG7ebYbcxs82s5rtvdcgkzUX2uK02y4wyeO4mX0lC5RM0S300JnfpVqNz"
			currencyCode="GBP"
			itemDescription="Test Results"
			itemName="Brain Rank Test @ IQResearches.com"
			price="12.37"
			sm-overflow-x="hidden"
			sm-overflow-y="hidden"
			priceMax="42.37"
			priceBase="12.50"
			priceCerti="12.50"
			priceReport="12.50"
			priceReview="12.50"
		>
			<Override
				slot="Content"
				justify-items="center"
				grid-gap="50px"
				max-width="1200px"
				sm-grid-template-columns="repeat(1, 1fr)"
				grid-template-columns="1fr 1fr"
				align-items="center"
				display="grid"
				md-grid-template-columns="repeat(1, 1fr)"
				padding="0px 25px 0px 25px"
				lg-grid-gap="20px"
				sm-padding="0px 8px 0px 8px"
				margin="0px auto 80px auto"
			/>
			<Override
				slot="GottaPay"
				position="fixed"
				width="100%"
				display="grid"
				align-items="center"
				justify-items="center"
				z-index="100000"
				color="--light"
				font="--lead"
				box-shadow="--xl"
				bottom={0}
				opacity="0.9"
				cursor="pointer"
			/>
			<Override
				slot="GPCWrapper"
				background="--color-light"
				id="gotopay"
				font="--base"
				margin="16px 0px 0 0px"
				border-radius="15px 15px 0 0"
				border-width="2px 2px 0 2px"
				border-style="solid"
				border-color="--color-amaranth"
				color="--amaranth"
				display="flex"
				padding="0px 16px 0px 16px"
			/>
			<Override
				slot="GottaPayLabel"
				align-self="center"
				padding="0px 16px 0px 0px"
				font="normal 400 17px/1.5 --fontFamily-googleRubik"
				color="--vividOrange"
			>
				Get your results after payment
			</Override>
			<Override
				slot="GPIcon"
				category="fa"
				icon={FaExternalLinkAlt}
				align-self="center"
				color="--vividOrange"
			/>
			<Override slot="PaymentBox" id="payment-box" lg-display="grid" padding="25px 0px 0px 0px" />
			<Override slot="Shortcut" background="none" padding="0 0 0 0" hover-color="--lightD2">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					below
				</Strong>
			</Override>
			<Override slot="DownIcon" category="fa" icon={FaAngleDoubleDown} hover-transform="scale(1)" />
			<Override
				slot="DownButton"
				margin="25px 0px 0px 0px"
				border-radius="5px"
				hover-transform="scale(1.1)"
				hover-transition="all 1s ease 0s"
				hover-opacity="0.8"
			>
				<div />
			</Override>
			<Override slot="ShortcutText">
				Now, you can review the questions you answered incorrectly below or anytime later at the Retrieve Results page. Questions that were skipped are not included in this assessment.
				<br />
				<div />
			</Override>
			<Override slot="ShortcutTextWrapper" justify-items="center" />
			<Override
				slot="CertGenBtn"
				hover-background="--color-sandyBrown"
				hover-transform="scale(1.05)"
				hover-transition="all 1s ease 0s"
				font="--lead"
			/>
			<Override slot="TitleWrapper" />
			<Override slot="Title" as="h2" color="--primary" font="--headline2">
				Get Your Certificate
			</Override>
			<Override slot="AR Title Wrapper" border-color="--color-primary" color="--primary" id="report" />
			<Override slot="AR Title" as="h2" font="--headline2" />
			<Override slot="Title Wrapper" color="--primary" />
			<Override slot="AirTable Wrapper" margin="25px 0px 25px 0px" />
			<Override slot="Label" font="normal 300 15px/1.5 --fontFamily-googlePoppins" />
			<Override slot="Failed Questions Wrapper" margin="25px 0px 25px 0px" />
			<Override slot="Certificate" background="rgba(112, 179, 255, 0.15)" padding="24px 0 80px 0" id="certificate" />
			<Override slot="Analytic Report" background="rgba(42, 157, 143, 0.15)" padding="24px 0 80px 0" id="report" />
			<Override slot="Failed Questions" background="rgba(244, 162, 97, 0.15)" padding="24px 0 80px 0" id="questions" />
			<Override slot="loadingPG pay success wrapper" height="262px" />
			<Override slot="loader wrapper" height="262px" />
			<Override slot="ChartWrapper" background="rgba(0, 0, 0, 0) linear-gradient(0deg,rgba(42, 157, 143, 0.15) 0%,rgba(0,0,0,0) 50%,rgba(42, 157, 143, 0.15) 100%) 0% 0% /auto repeat scroll padding-box" padding="0 0 24px 0">
				<Override slot="SectionContent" />
			</Override>
			<Override slot="Chart" display="flex" />
			<Override
				slot="ChartTitle"
				text-align="center"
				font="--headline2"
				color="--primary"
				margin="16px 0px 40px 0px"
			/>
			<Override slot="Name Input" />
			<Override slot="NameTooltip" border-width={0} border-style="none">
				<Override slot="Tooltip" />
			</Override>
			<Override
				slot="PayTitleIcon"
				category="fa"
				color="--lightBlue"
				align-self="center"
				font="32px Poppins, sans-serif"
				icon={FaRegQuestionCircle}
			/>
			<Override slot="Title Wapper" display="flex" />
			<Override slot="TooltipIconWrapper" display="flex" />
			<Override slot="Payment Subtitle" font="italic 300 15px/25.5px Poppins, sans-serif" margin="16px 0px 16px 5px" />
			<Override slot="Payment Title" align-self="center" />
			<Override
				slot="CustomTooltip"
				tooltipContent="Provide your name for the certificate and email for test result access. No marketing emails will be sent. Data held for 90 days; email hello@iqresearches.com for earlier removal. Continue to payment via PayPal's secure gateway."
				border-style="none"
				interactionType="click"
				cursor="pointer"
				borderRadius="15px"
				border="1px solid #70B3FF "
				color="--primary"
				backgroundColor="rgba(241, 246, 249, 0.8)"
				padding="0 10px 0 10px"
				sm-position="relative"
				offsetLeft="-100"
			>
				<Override slot="Tooltip" />
			</Override>
			<Override slot="PaypalLink" color="--lightBlue" hover-color="--secondary" target="_blank" />
			<Override slot="Data Privacy" margin="16px 0px 32px 0px" text-align="justify">
				We don’t store payment details; transactions occur directly between your browser and PayPal, without the need for a registered PayPal account. See PayPal’s{" "}
				<Link
					href="https://www.paypal.com/us/legalhub/privacy-full"
					color="--lightBlue"
					hover-color="--secondary"
					target="_blank"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Privacy Statement
				</Link>
				{" "}for more details.
			</Override>
			<Override
				slot="PaymentFooterIcon1"
				color="--grey"
				cursor="pointer"
				category="fa"
				icon={FaCcStripe}
			/>
			<Override
				slot="PaymentFooterIcon2"
				color="--grey"
				cursor="pointer"
				category="fa"
				icon={FaShieldAlt}
			/>
			<Override slot="PaymentFooterIcon3" color="--grey" cursor="pointer" />
			<Override slot="PaymentFooterWrapper" />
			<Override
				slot="CustomTooltipIcon1"
				border-style="none"
				interactionType="click"
				tooltipContent="Your payment security is our priority. Transactions are secured through PayPal. Learn more about PayPal's safety measures: https://www.paypal.com/uk/webapps/mpp/paypal-safety-and-security"
				backgroundColor="rgba(241, 246, 249, 0.8)"
				padding="0 10px 0 10px"
				border="1px solid #70B3FF "
				borderRadius="15px"
				font="--base"
				text-align="left"
				popupContent="Your payment security is our priority. Transactions are secured through Stripe. Learn more about Stripe's safety measures: https://stripe.com"
				maxWidth="360px"
				position="relative"
				z-index="10"
			/>
			<Override
				slot="CustomTooltipIcon2"
				border-style="none"
				interactionType="click"
				tooltipContent="We use Cloudflare to enhance our website's security, ensuring a fast, secure, and seamless experience. Cloudflare's global network offers advanced protection against DDoS attacks and provides unique threat intelligence, making our site more reliable and secure for users. For more details on how Cloudflare secures our site, visit https://www.cloudflare.com/en-gb/security/ ."
				backgroundColor="rgba(241, 246, 249, 0.8)"
				padding="0 10px 0 10px"
				border="1px solid #70B3FF "
				borderRadius="15px"
				font="--base"
				text-align="left"
				offsetLeft="-150"
				popupContent="We use Cloudflare to enhance our website's security, ensuring a fast, secure, and seamless experience. Cloudflare's global network offers advanced protection against DDoS attacks and provides unique threat intelligence, making our site more reliable and secure for users. For more details on how Cloudflare secures our site, visit https://www.cloudflare.com/en-gb/security/ ."
				maxWidth="360px"
				position="relative"
				z-index="10"
			/>
			<Override
				slot="CustomTooltipIcon3"
				border-style="none"
				interactionType="click"
				tooltipContent="We prioritise data security and privacy, not storing passwords or personal details. Only emails are kept for 90 days to facilitate result retrieval, securely stored in our cloud database, ensuring your information is protected and accessible only to you."
				backgroundColor="rgba(241, 246, 249, 0.8)"
				padding="0 10px 0 10px"
				border="1px solid #70B3FF "
				borderRadius="15px"
				font="--base"
				text-align="left"
				offsetLeft="-300"
				popupContent="We prioritise data security and privacy, not storing passwords or personal details. Only emails are kept for 90 days to facilitate result retrieval, securely stored in our cloud database, ensuring your information is protected and accessible only to you."
				maxWidth="360px"
				position="relative"
				z-index="10"
			/>
			<Override slot="ReactTooltip" popupContent="Provide your name for the certificate and email for test result access. No marketing emails will be sent. Data held for 90 days; email hello@iqresearches.com for earlier removal. Continue to payment via PayPal's secure gateway." margin="0px 0px 0px 15px" maxWidth="360px" />
			<Override slot="PricePanel">
				<Override slot="button" mix-blend-mode="normal" background="--color-primary" />
				<Override slot="button1" mix-blend-mode="normal" background="--color-primary" />
				<Override slot="button2" mix-blend-mode="normal" background="--color-primary" />
				<Override slot="box9" text-align="left" padding="0px 0px 0px 15px" />
				<Override slot="text8" text-align="left" />
				<Override slot="text9" text-align="left" />
				<Override slot="text5" text-align="left" />
				<Override slot="text6" text-align="left" />
				<Override slot="box6" padding="0px 0px 0px 15px" />
				<Override slot="text11" text-align="left" />
				<Override slot="text12" text-align="left" />
				<Override slot="box12" padding="0px 0px 0px 15px" />
				<Override slot="text14" text-align="left" />
				<Override slot="text15" text-align="left" />
				<Override slot="box15" padding="0px 0px 0px 15px" />
			</Override>
			<Override slot="ScoreWrapper" background="rgba(42, 157, 143, 0.15) 100%" />
			<Override slot="OnlyScore" font="--headline2" color="--primary" />
			<Override slot="FeatureMenu">
				<Override slot="box2" />
			</Override>
			<Override slot="PayPal" position="relative" z-index="9">
				<Override slot="CardElementBox" />
				<Override slot="NameInput" display="grid" grid-template-columns="1fr 1fr" grid-gap="12px " />
				<Override
					slot="EmailInput"
					width="100%"
					border-width="1px"
					transition="background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease"
					border-color="#e6e6e6"
					box-shadow="0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)"
					border-radius="5px"
					focus-border-color="#0573e180"
					focus-box-shadow="0px 1px 1px 3px #0573e140"
				/>
				<Override
					slot="FirstNameInput"
					border-width="1px"
					transition="background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease"
					border-color="#e6e6e6"
					box-shadow="0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)"
					border-radius="5px"
					focus-border-color="#0573e180"
					focus-box-shadow="0px 1px 1px 3px #0573e140"
					width="100%"
				/>
				<Override
					slot="LastNameInput"
					border-width="1px"
					transition="background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease"
					border-color="#e6e6e6"
					box-shadow="0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)"
					border-radius="5px"
					focus-border-color="#0573e180"
					focus-box-shadow="0px 1px 1px 3px #0573e140"
					width="100%"
				/>
				<Override slot="SubmitButton" background="--color-vividOrange" width="100%" />
			</Override>
			<Override slot="PayHeading" font="400 36px/43.2px Poppins, sans-serif" />
			<Components.PricePanel />
		</Components.PaymentWrapper>
		<Components.Footer>
			<Override slot="text1" />
			<Override slot="text">
				The content provided here is for informational and educational purposes and should not replace professional medical guidance, diagnosis, or treatment.
				<br />
				An IQ test measures specific aspects of cognitive ability and should not be seen as the sole indicator of one’s intellectual capacity or potential.
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});